<template>
  <div class="wrapper">
    <div v-if="!loaded">
      <div
        style="margin-top: 24px; position: absolute; top: 30%; left: 50%"
        class="loader"
      ></div>
    </div>

    <BaseDetailviewContainer
      v-if="loaded"
      :key="userUpdated"
      :width="'720px'"
      :padding="'32px'"
    >
      <BaseAccordion
        @buttonPressed="openBrokerPanelEdit(broker)"
        :title="$t('MyDetails')"
        :separator-line="true"
        :padding-top="'0'"
        :button-name="$t('Edit')"
        :button-icon="'edit_16_16.svg'"
        :show-bot-divider="true"
        :identifier="'myDetails'"
        :parent-name="'brokerSettings'"
        :button-opens-accordion="true"
      >
        <div class="user-panel-body" id="user-body">
          <div class="main-info-section">
            <v-row v-if="broker.name" class="user-field-row">
              <p class="field-name content">{{ $t("FullName") }}</p>
              <p class="field-value">{{ broker.name }}</p>
            </v-row>
            <v-row v-if="broker.email" class="user-field-row">
              <p class="field-name content">{{ $t("e-mail") }}</p>
              <p class="field-value">{{ broker.email }}</p>
            </v-row>
            <v-row class="user-field-row">
              <p class="field-name content">{{ $t("Password") }}</p>
              <p
                class="field-value underlined"
                style="cursor: pointer"
                @click="openChangePasswordModal"
              >
                {{ $t("ChangePassword") }}
              </p>
            </v-row>
            <v-row v-if="broker.phoneNumber" class="user-field-row">
              <p class="field-name content">{{ $t("PhoneNum") }}</p>
              <p class="field-value">{{ broker.phoneNumber }}</p>
            </v-row>
            <v-row v-if="broker.personalCode" class="user-field-row">
              <p class="field-name content">{{ $t("PersonalCode") }}</p>
              <p class="field-value">{{ broker.personalCode }}</p>
            </v-row>
            <v-row
              v-if="
                broker.types &&
                !Object.values(broker.types).every((x) => x.value === false)
              "
              class="user-field-row"
            >
              <p class="field-name content">{{ $t("Role") }}</p>
              <p class="field-value">{{ formatUserObject(broker.types) }}</p>
            </v-row>
            <v-row v-if="broker.languages" class="user-field-row">
              <p class="field-name content">{{ $t("Languages") }}</p>
              <p class="field-value">
                {{ formatUserObject(broker.languages) }}
              </p>
            </v-row>
            <v-row v-if="broker.groups" class="user-field-row">
              <p class="field-name">{{ $t("Groups") }}</p>
              <div class="field-value">
                <p v-if="broker.groups.length">
                  {{ formatGroupField(broker) }}
                </p>
                <p v-if="!broker.groups.length">
                  {{ $t("UnAllocated") }}
                </p>
              </div>
            </v-row>
            <v-row
              v-if="
                broker.groups.length &&
                broker.groups.filter((x) => x.userDefault).length
              "
              class="user-field-row"
            >
              <p class="field-name">{{ $t("DefaultGroup") }}</p>
              <div class="field-value">
                <p>
                  {{ formatDefaultGroup(broker.groups) }}
                </p>
              </div>
            </v-row>
            <v-row class="user-field-row">
              <p class="field-name">{{ $t("AccessLevel") }}</p>
              <p class="field-value">{{ formatUserRole(broker) }}</p>
            </v-row>
            <v-row
              v-if="broker.groups.length && broker.isGroupAdmin"
              class="user-field-row"
            >
              <p class="field-name">{{ $t("AdminInGroups") }}</p>
              <div class="field-value">
                <p>
                  {{ formatAdminInGroups(broker.groups) }}
                </p>
              </div>
            </v-row>
            <v-row v-if="broker.personalWWWPage" class="user-field-row">
              <p class="field-name content">{{ $t("PersonalWWWPage") }}</p>
              <p
                @click="openLink(broker.personalWWWPage)"
                class="field-value hoverable-link"
              >
                {{ broker.personalWWWPage }}
              </p>
            </v-row>
            <v-row
              v-if="
                broker.socials &&
                !Object.values(broker.socials).every((x) => x === '')
              "
              class="user-field-row"
            >
              <p class="field-name content">{{ $t("SocialMedia") }}</p>
              <div class="social-images" style="display: flex; gap: 12px">
                <img
                  v-if="broker.socials.facebook"
                  @click="openLink(broker.socials.facebook)"
                  style="cursor: pointer"
                  alt=""
                  src="../../assets/images/facebook.svg"
                />
                <img
                  v-if="broker.socials.instagram"
                  @click="openLink(broker.socials.instagram)"
                  style="cursor: pointer"
                  alt=""
                  src="../../assets/images/instagram.svg"
                />
                <img
                  v-if="broker.socials.linkedIn"
                  @click="openLink(broker.socials.linkedIn)"
                  style="cursor: pointer"
                  alt=""
                  src="../../assets/images/linkedIn.svg"
                />
                <img
                  v-if="broker.socials.tiktok"
                  @click="openLink(broker.socials.tiktok)"
                  style="cursor: pointer"
                  alt=""
                  src="../../assets/images/tiktok.svg"
                />
                <img
                  v-if="broker.socials.youtube"
                  style="cursor: pointer"
                  @click="openLink(broker.socials.youtube)"
                  alt=""
                  src="../../assets/images/youtube.svg"
                />
              </div>
            </v-row>
            <v-row
              v-if="
                broker.occupation &&
                !Object.values(broker.occupation).every((x) => x === '')
              "
              class="user-field-row"
            >
              <p class="field-name content">{{ $t("Occupation") }}</p>

               <div style="display: flex; flex-direction: column; gap: 12px">
                <div
                  v-for="occupation of Object.values(broker.occupation).filter((x) => x !== '')"
                  :key="occupation"
                  class="content-normal"
                  style="width: 100%; flex-wrap: nowrap"
                >
                  <p style="max-width: 464px">{{ occupation }}</p>
                </div>
              </div>
            </v-row>
            <v-row
              v-if="
                broker.profilePicture &&
                broker.profilePicture.length &&
                broker.profilePicture[0].name
              "
              class="user-field-row"
              id="lightgallery-user"
            >
              <p class="field-name content">{{ $t("Image") }}</p>
              <img
                @click="openImageGallery"
                class="profile-picture"
                :src="broker.profilePicture[0].name"
                alt=""
              />
            </v-row>
            <!--  <v-row
              v-if="
                broker.profilePicture &&
                broker.profilePicture.length &&
                broker.profilePicture[0].name
              "
              id="lightgallery-user"
              style="margin-top: 24px"
            >
              <img
                @click="openImageGallery"
                class="profile-picture"
                :src="broker.profilePicture[0].name"
                alt=""
              />
            </v-row> -->
            <v-row
              style="display: flex; flex-wrap: nowrap"
              v-if="
                broker.about &&
                !Object.values(broker.about).every((x) => x === '')
              "
              class="user-field-row"
            >
              <p class="field-name content">{{ $t("Description") }}</p>

              <div style="display: flex; flex-direction: column; gap: 12px">
                <div
                  v-for="desc of Object.values(broker.about).filter((x) => x !== '')"
                  :key="desc"
                  class="content-normal"
                  style="width: 100%; flex-wrap: nowrap"
                >
                  <p style="max-width: 464px">{{ desc }}</p>
                </div>
              </div>
            </v-row>
          </div>
        </div>
      </BaseAccordion>
      <BaseAccordion
        :title="$t('MyFeedback')"
        :separator-line="true"
        :identifier="'feedback'"
        :show-bot-divider="true"
        :parent-name="'brokerSettings'"
        :buttons-open-accordion="true"
        :padding-top="'32px'"
        :button-name="$t('Edit')"
        :button-icon="'edit_16_16.svg'"
        @buttonPressed="openBrokerPanelFeedback(broker)"
      >
        <div class="content" v-if="!broker.clients">
          {{ this.$t("NoFeedback") }}
        </div>
        <v-row v-if="broker.clients && feedback.length">
          <div class="description content" style="margin-top: 0px">
            <v-row
              class="description-item"
              style="margin-top: 0px; padding-top: 0px"
            >
              <textarea
                style="auto-scale-text;"
                outlined
                rows="3"
                id="text-area"
                v-if="!showFullText"
                readonly
                class="description-item1 content"
                v-model="feedback"
              >
              </textarea>
            </v-row>
            <div
              class="show-more-button"
              v-bind:class="{ hidden: showFullText }"
            >
              <button
                style="text-decoration-line: underline"
                for="expanded"
                @click="showText()"
                id="textButton"
              >
                <p
                  v-if="
                    broker.clients &&
                    (feedback.length > 456 || this.rowCount > 5)
                  "
                  class="link"
                >
                  {{ $t("ShowMore") }}
                </p>
              </button>
            </div>
            <v-row style="margin-top: 0px; width: 656px">
              <v-textarea
                hide-details
                v-if="showFullText"
                name="input-7-1"
                auto-grow
                readonly
                color="black"
                class="description-textarea content"
                v-model="feedback"
              ></v-textarea>
            </v-row>
            <div
              class="show-less-button"
              v-bind:class="{ hidden: !showFullText }"
            >
              <button
                style="padding-left: 0px; text-decoration-line: underline"
                for="expanded"
                @click="showTextLess()"
                id="textButton"
              >
                <p class="link">
                  {{ $t("ShowLess") }}
                </p>
              </button>
            </div>
          </div>
        </v-row>
      </BaseAccordion>
      <BaseAccordion
        :title="$t('Achievements')"
        :separator-line="true"
        :identifier="'achievements'"
        :parent-name="'brokerSettings'"
        :buttons-open-accordion="true"
        :show-bot-divider="true"
        :padding-top="'32px'"
        :button-name="$t('Edit')"
        :button-icon="'edit_16_16.svg'"
        @buttonPressed="openBrokerPanelAchievements(broker)"
      >
        <div class="content" v-if="!broker.achievements">
          {{ this.$t("NoAchievements") }}
        </div>
        <div
          class="content"
          v-if="broker.achievements"
        >
           <div style="display: flex; flex-direction: column; gap: 12px">
                <div
                  v-for="achievement of Object.values(broker.achievements).filter((x) => x !== '')"
                  :key="achievement"
                  class="content-normal"
                  style="flex-wrap: nowrap; word-break: break-word"
                >
                  <p style="max-width: 656px">{{ achievement }}</p>
                </div>
              </div>
        </div>
      </BaseAccordion>
      <BaseAccordion
        :title="$t('ListingLastImage')"
        :separator-line="true"
        :identifier="'listingLastImage'"
        :parent-name="'brokerSettings'"
        :button-opens-accordion="true"
        :padding-bottom="'0'"
      >
        <div style="max-width: 251px">
          <ImageUpload
            :maxAmountOfImages="1"
            @change="putImages"
            :can-delete="true"
            :images="brokerLastImage"
            :label="$t('UploadImage')"
            :compress="true"
            :drag-and-drop="true"
          />
        </div>
      </BaseAccordion>
    </BaseDetailviewContainer>

    <ChangePasswordModal
      v-if="showChangePasswordModal"
      @close-modal="showChangePasswordModal = false"
      :broker="broker"
    />
    <AddUserSidepanel
      :edit="true"
      :editable-user="broker"
      :user-settings="true"
      @closed="closeEditUserPanel"
      v-if="userSidepanelOpen"
      :feedback-only="showFeedbackField"
      :achievements-only="showAchievementsField"
    />
  </div>
</template>

<script>
import BaseDetailviewContainer from "@/components/common/BaseDetailviewContainer";
import BaseAccordion from "@/components/common/BaseAccordion";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import ChangePasswordModal from "@/components/Admin/ChangePasswordModal";
import Admin from "@/http/admin";
import ImageUpload from "@/components/common/ImageUpload";
import AddUserSidepanel from "@/components/Admin/AddUserSidepanel";
import "lightgallery.js";
import "lightgallery.js/dist/css/lightgallery.css";

export default {
  name: "BrokerSettingsContent",
  components: {
    ImageUpload,
    BaseDetailviewContainer,
    BaseAccordion,
    ChangePasswordModal,
    AddUserSidepanel,
  },
  data() {
    return {
      loaded: false,
      showChangePasswordModal: false,
      broker: "",
      brokerLastImage: "",
      userSidepanelOpen: false,
      userUpdated: 0,
      showFeedbackField: false,
      showAchievementsField: false,
      showFullText: false,
      rowCount: 0,
    };
  },
  created() {
    this.setup();
  },
  computed: {
    ...mapGetters(["user", "brokerSettingsAccordionsStates"]),
    feedback() {
      let result = [];
      for (let value of Object.values(this.broker.clients)) {
        result.push(value);
      }
      return result.join("\r\n\n");
    },
  },
  methods: {
    ...mapActions(["setBrokerSettingsAccordionState"]),
    async setup() {
      await this.getUserDetails();
      await this.getListingLastImage();
      this.checkRows();
      this.loaded = true;
    },
    async getListingLastImage() {
      if (this.broker.brokerLstImage) {
        this.brokerLastImage = this.broker.brokerLstImage;
      }
    },
    async getUserDetails() {
      const response = await axios.get(`/api/user/${this.user._id}`);
      this.broker = response.data.user;
    },
    openChangePasswordModal() {
      this.showChangePasswordModal = true;
    },
    async closeEditUserPanel() {
      this.showAchievementsField = false;
      this.showFeedbackField = false;
      this.userSidepanelOpen = false;
      await this.getUserDetails();
      this.userUpdated++;
    },
    showText() {
      this.showFullText = true;
    },
    showTextLess() {
      this.showFullText = false;
    },
    openBrokerPanelEdit() {
      this.userSidepanelOpen = true;
    },
    openBrokerPanelFeedback() {
      this.showFeedbackField = true;
      this.userSidepanelOpen = true;
    },
    openBrokerPanelAchievements() {
      this.showAchievementsField = true;
      this.userSidepanelOpen = true;
    },
    putImages(e) {
      this.brokerLastImage = e;
      this.saveLastImage();
    },
    checkRows() {
      if (this.user.feedback) {
        var el = this.user.feedback;
        var lineCount = 0;
        var lines = el.split("\n");
        for (var i = 0; i < lines.length; i++) {
          if (lines[i].length > 0) {
            lineCount++;
          }
        }
        this.rowCount = lineCount;
      }
    },
    async saveLastImage() {
      const response = await Admin.saveBrokerLstImage(this.brokerLastImage, this.user._id);
      console.log("LAST IMAGE RESPONSE", response);
    },
    formatUserObject(object) {
      let result = [];
      for (const key in object) {
        if (object[key].value) {
          result.push(this.$t(key));
        }
        if (object[key] === 1) {
          result.push(this.$t(key.toUpperCase()));
        }
      }
      return result.join(", ");
    },
    formatGroupField(user) {
      let result = [];
      for (let group of user.groups) {
        result.push(group.name);
      }
      return result.join(", ");
    },
    formatUserRole(user) {
      if (user.isAdmin) {
        return "Admin";
      } else if (user.isGroupAdmin) {
        return this.$t("GroupAdmin");
      } else {
        return this.$t("User");
      }
    },
    formatDefaultGroup(groups) {
      let result = [];
      groups.forEach((group) => {
        if (group.userDefault) {
          result.push(group.name);
        }
      });
      return result[0] ? result[0] : "-";
    },
    formatAdminInGroups(groups) {
      let result = [];
      groups.forEach((group) => {
        if (group.isGroupAdmin) {
          result.push(group.name);
        }
      });
      return result[0] ? result.join(", ") : "-";
    },
    openImageGallery() {
      let dynamicEl = [];
      dynamicEl.push({ src: this.broker.profilePicture[0].name });
      let imageGallery = null;
      const imageWrapper = document.getElementById("lightgallery-user");
      if (imageWrapper) {
        imageGallery = window.lightGallery(imageWrapper, {
          dynamic: true,
          dynamicEl,
        });
      }
      console.log(imageGallery);
    },
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.wrapper {
  width: 100%;
  height: 100%;
  padding: 32px 100px 32px 100px;
}

#broker-settings-content {
  display: flex;
  flex-direction: column;
  max-width: 720px;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 0 32px 0 32px;
}

.main-info-section {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.user-field-row {
  gap: 24px;
}

.field-name {
  line-height: 20px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  width: 168px;
  height: 20px;
  color: #75787a;
}

.field-value {
  line-height: 20px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

.profile-picture {
  max-width: 130px;
  height: 130px;
  cursor: pointer;
  border-radius: 8px;
}

.underlined {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.small-uploader-en {
  width: 120px !important;
}

.small-uploader-et {
  width: 100px !important;
}

.image-uploader {
  /*max-height: 32px;*/
  /*max-width: 90px;*/
  width: 100%;
  border: 1px dashed #000000;
  box-sizing: border-box;
  border-radius: 8px;

  margin: 0;

  ::v-deep .beforeUpload .icon {
    height: 16px;
    width: auto;
    margin: 0 8px;
  }

  ::v-deep .beforeUpload {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0 !important;
  }

  ::v-deep .beforeUpload .mainMessage {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
  }
}

.broker-last-image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 12px;
}

::v-deep .delete {
  padding: 0 !important;
}

::v-deep .plus {
  display: none;
}
.hoverable-link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.v-text-field {
  padding: 0;
}

.description {
  /* padding-left: 24px;
  padding-top: 0px;
  padding-bottom: 24px;
  padding-right: 56px; */
  border: none !important;
  outline: none !important;
  width: 100%;
  min-height: 128px;
  resize: none;
}
.description-item {
  text-overflow: ellipsis;
}

.description-item1 {
  border: none !important;
  outline: none !important;
  width: 656px;
  height: 100px;
  resize: none;
  padding-bottom: 58px;
  padding-top: 0px;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.show-more-button {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
}
.show-less-button {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
}
::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
  width: 0%;
}
::v-deep .v-text-field > .v-input__control > .v-input__slot:after {
  display: none;
}

::v-deep .v-textarea textarea {
  line-height: unset;
  color: black !important;
  width: 656px;
}
.hidden {
  display: none;
}
.white-space {
  white-space: pre-line;
}
</style>